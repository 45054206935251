import axios from "axios";
const { VUE_APP_VAULTPERK_URL, VUE_APP_PERK_KEY } = process.env;

export default class solaireService {

  static async getCars() {
    try {

      //Llamada al controlador oncologico en V-P, para la busqueda de planes garantia
      const vaultRes = await axios.get(
        `${VUE_APP_VAULTPERK_URL}/cartSoap/getAll`,
        {
          mode: 'cors',
          headers: {
            perk_key: VUE_APP_PERK_KEY,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }
        }
      );

      return vaultRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los registro de automoviles",
      };
    }
  }

}