import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;

export default class solaireService {

    static async consultaCar(patente) {
        const vaultRes = await axios.get(`${VUE_APP_SHIELD_URL}/cartSoap/getPatenteDasboard/${patente}`
        );
        return vaultRes.data;
      }
    

}